import React, { useRef, useState } from "react";

import estilo from "./styles/Formulario.module.css";
import { useContext } from "react";
import { DataContext } from "../context/Contexto";

import emailjs from "emailjs-com";

function Formulario() {
  const { activarForm, setActivarForm } = useContext(DataContext);

  const [telefono, setTelefono] = useState("");
  const [enviando, setEnviando] = useState(false);
  const [confirmacion, setConfirmacion] = useState("");
  const [error, setError] = useState("");

  function telefonoValidar(e) {
    const inputValue = e.target.value;
    // Elimina caracteres no numéricos
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Limita la longitud a un máximo de 9 caracteres
    if (numericValue.length <= 9) {
      setTelefono(numericValue);
    }
  }

  function formularioCerrar() {
    setActivarForm(!activarForm);
  }

  const form = useRef();

  function enviarCorreo(e) {
    e.preventDefault();

    setEnviando(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setConfirmacion("Correo enviado con exito");
          setError("");
          setEnviando(false);
          //resetear formulario
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          setError("Ocurrio un error al enviar correo");
          setConfirmacion("");
          setEnviando(false);
        }
      );
  }

  return (
    <div className={estilo.formulario}>
      <form ref={form} onSubmit={enviarCorreo}>
        <div onClick={formularioCerrar} className={estilo.boton_cerrar}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z" />
          </svg>
        </div>

        <div className={estilo.contenedorInput}>
          <label>Nombre</label>
          <input
            type="text"
            name="name"
            placeholder="Escribe tu nombre..."
            required
          />
        </div>

        <div className={estilo.contenedorInput}>
          <label>Correo</label>
          <input
            type="email"
            name="email"
            placeholder="Escribe tu correo..."
            required
          />
        </div>

        <div className={estilo.contenedorInput}>
          <label>Teléfono</label>
          <input
            value={telefono}
            onChange={telefonoValidar}
            type="tel"
            name="phone"
            placeholder="Escribe tu teléfono..."
            required
          />
        </div>

        <div className={estilo.contenedorInput}>
          <label>Mensaje</label>
          <textarea
            name="message"
            rows="4"
            placeholder="Escribe tu mensaje..."
            required
          />
        </div>

        <button type="submit" value="ENVIAR">
          {enviando ? "Enviando..." : "Enviar"}
        </button>

        {confirmacion || error ? (
          <div className={estilo.alerta}>
            {confirmacion && (
              <p className={estilo.confirmacion}>{confirmacion}</p>
            )}
            {error && <p className={estilo.error}>{error}</p>}
          </div>
        ) : null}
      </form>
    </div>
  );
}

export default Formulario;
