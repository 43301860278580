import React, { useEffect, useState } from "react";

import estilo from "./styles/Menu.module.css";
import { Link } from "react-scroll";

function Menu() {
  //activar menu
  const [activar, setActivar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 960) {
        setActivar(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function cambiarActivo() {
    if (window.innerWidth <= 960) {
      setActivar(!activar);
    }
  }

  return (
    <div className={estilo.menu}>
      <div className={estilo.nombre}>
        <svg width="50" height="50" viewBox="0 0 50 34" onClick={cambiarActivo}>
          <path d="M0 28.3333H49.6923V34H0V28.3333ZM0 14.1667H49.6923V19.8333H0V14.1667ZM0 0H49.6923V5.66667H0V0Z" />
        </svg>

        <h2>BRYAN</h2>
      </div>

      <ul className={activar ? estilo.menu_lista_activar : estilo.menu_lista}>
        <li>
          <Link
            activeClass="active"
            to="sobre"
            spy={true}
            smooth={true}
            offset={0}
            duration={750}
            onClick={cambiarActivo}
          >
            Sobre Mi
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="proyectos"
            spy={true}
            smooth={true}
            offset={0}
            duration={750}
            onClick={cambiarActivo}
          >
            Proyectos
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="servicios"
            spy={true}
            smooth={true}
            offset={0}
            duration={750}
            onClick={cambiarActivo}
          >
            Servicios
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="experiencia"
            spy={true}
            smooth={true}
            offset={0}
            duration={750}
            onClick={cambiarActivo}
          >
            Experiencia
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="contactame"
            spy={true}
            smooth={true}
            offset={0}
            duration={750}
            onClick={cambiarActivo}
          >
            Contáctame
          </Link>
        </li>
        <svg width="40" height="40" viewBox="0 0 40 40" onClick={cambiarActivo}>
          <path d="M20 0C8.85714 0 0 8.85714 0 20C0 31.1429 8.85714 40 20 40C31.1429 40 40 31.1429 40 20C40 8.85714 31.1429 0 20 0ZM27.7143 30L20 22.2857L12.2857 30L10 27.7143L17.7143 20L10 12.2857L12.2857 10L20 17.7143L27.7143 10L30 12.2857L22.2857 20L30 27.7143L27.7143 30Z" />
        </svg>
      </ul>
    </div>
  );
}

export default Menu;
