import React, { useState } from "react";

import estilo from "./styles/Proyectos.module.css";
import listaProyectos from "../json/informacion.json";
import lista from "../json/listaTecnologias";

function Proyectos() {
  // let lista = [
  //   "Figma",
  //   "React",
  //   "Js",
  //   "Html5",
  //   "Css",
  //   "Supabase",
  //   "NextJs",
  //   "Cordova",
  //   "Photoshop",
  //   "Illustrator",
  // ];

  const [activarModalFoto, setActivarModalFoto] = useState(false);
  const [linkFotoModal, setLinkFotoModal] = useState("");

  function modalFoto(e) {
    setLinkFotoModal(e.target.value);
    setActivarModalFoto(!activarModalFoto);
  }

  return (
    <div className={estilo.proyectos} id="proyectos">
      <div className={estilo.texto_proyectos}>
        <h3>Proyectos</h3>
        <p>Ejemplos visuales de mi habilidad y creatividad en acción</p>
      </div>

      <div className={estilo.grid_proyecto}>
        {listaProyectos.map((e, i) => (
          <div key={i} className={estilo.item_proyecto}>
            {/* <div className={estilo.foto}>
              <Foto src={e.foto} nombre={e.nombre}></Foto>
            </div> */}

            <div className={estilo.foto}>
              <img src={e.foto} alt="proyecto" loading="lazy" />

              <div className={estilo.lista_iconos}>
                <div className={estilo.detalles}>
                  {/* <h5>Activar Zoom</h5> */}
                  <p>{e.detalles}</p>
                </div>

                <div className={estilo.contenido_lista}>
                  {lista
                    .filter((icono) => e.tecnologias.includes(icono.nombre))
                    .map((icono, index) => (
                      <a
                        key={index}
                        href={icono.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={estilo.lista}>{icono.icono}</div>
                      </a>
                    ))}
                </div>

                <div className={estilo.botones_hover}>
                  <button value={e.foto} onClick={modalFoto}>
                    Ver Foto
                  </button>
                  <a href={e.foto} target="_blank" rel="noopener noreferrer">
                    Abrir Foto
                  </a>
                  <a href={e.link} target="_blank" rel="noopener noreferrer">
                    Ver Página
                  </a>
                </div>
              </div>

              <div className={estilo.fondo1}></div>
              <div className={estilo.fondo2}></div>
            </div>

            <div className={estilo.item_contenido}>
              <h4>{e.nombre}</h4>

              <div className={estilo.item_datos}>
                <p>
                  <em>Fecha:</em> {e.fecha} - {e.mes}
                </p>
                <p className={estilo.color_tecnologias}>
                  <em>Tecnologías:</em> {e.tecnologias.join(", ")}
                </p>

                {e.link.includes("play.google.com") ? (
                  <p>
                    <em>Link:</em>{" "}
                    <a href={e.link} target="_blank" rel="noopener noreferrer">
                      https://play.google.com/{e.nombre}
                    </a>
                  </p>
                ) : (
                  <p>
                    <em>Link:</em>{" "}
                    <a href={e.link} target="_blank" rel="noopener noreferrer">
                      {e.link}
                    </a>
                  </p>
                )}

                {e.figma ? (
                  <p>
                    <em>Figma:</em>{" "}
                    <a href={e.figma} target="_blank" rel="noopener noreferrer">
                      https://www.figma.com/{e.nombre}
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* modal */}

      {activarModalFoto && (
        <div className={estilo.modal_foto}>
          <div className={estilo.modal_foto_url}>
            <img src={linkFotoModal} alt="foto" loading="lazy" />

            <button onClick={modalFoto}>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z" />
              </svg>
            </button>
          </div>

          <div className={estilo.bg} onClick={modalFoto}></div>
        </div>
      )}
    </div>
  );
}

export default Proyectos;
