import React from "react";

import estilo from "./styles/Experiencia.module.css";

function Experiencia() {
  return (
    <div className={estilo.experiencia} id="experiencia">
      <div className={estilo.texto_experiencia}>
        <h3>Experiencia</h3>
      </div>

      <div className={estilo.grid_experiencia}>
        <div className={estilo.item_1}>
          <div className={estilo.contenido_item_1}>
            <h4>Devdatep</h4>
            <p>Mayo - Agosto (2023)</p>
          </div>

          <div className={estilo.contenido_item_1}>
            <h4>MARACANÁ STORE</h4>
            <p>Septiembre (2023) - Junio (2024)</p>
          </div>
        </div>

        <div className={estilo.linea}>
          <div className={estilo.circulos}>
            <div className={estilo.linea_circulo}></div>
            <div className={estilo.linea_circulo}></div>
          </div>
        </div>

        <div className={estilo.item_2}>
          <div className={estilo.contenido_item_2}>
            <h4>Diseño Web</h4>
            <p>Lider del área de Diseño Web</p>
          </div>

          <div className={estilo.contenido_item_2}>
            <h4>Desarrollo web</h4>
            <p>Diseñador y desarrollador web</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experiencia;
