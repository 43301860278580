import React, { useContext } from "react";

import estilo from "./styles/Presentacion.module.css";
import fotoPresentacion from "../img/fotoPerfil.png";

import { DataContext } from "../context/Contexto";
import Type from "./Type";

function Presentacion() {
  const { activarForm, setActivarForm } = useContext(DataContext);

  function formularioActivar() {
    setActivarForm(!activarForm);
  }

  return (
    <div className={estilo.presentacion} id="presentacion">
      <div className={estilo.foto}>
        <img src={fotoPresentacion} alt="bryan-ticona-valencia" />
      </div>

      <div className={estilo.tarjeta}>
        <div className={estilo.tarjeta_titulos}>
          <h3>El Desarrollo Web</h3>
          {/* <h3>Es mi Pasión</h3> */}
          <Type texto={"Es mi Pasión"}></Type>
        </div>

        <p>
          Una de las cosas que mas disfruto hacer es diseñar y realizar
          proyectos complejos mezclando diversas tecnologías de gran interés en
          el mercado
        </p>

        <div className={estilo.boton} onClick={formularioActivar}>
          <p>Contactar</p>
          <svg width="86" height="70" viewBox="0 0 86 70" fill="none">
            <path d="M86 18.1623V56.5385C86 60.1087 84.5843 63.5327 82.0642 66.0572C79.5442 68.5817 76.1263 70 72.5625 70H13.4375C9.87365 70 6.45577 68.5817 3.93575 66.0572C1.41573 63.5327 0 60.1087 0 56.5385V18.1623L41.6348 42.7054C42.0484 42.9498 42.5198 43.0787 43 43.0787C43.4802 43.0787 43.9516 42.9498 44.3652 42.7054L86 18.1623ZM72.5625 1.66668e-07C75.8689 -0.000520778 79.0594 1.22019 81.5237 3.4286C83.988 5.63701 85.553 8.67807 85.9194 11.97L43 37.2615L0.0806256 11.97C0.446976 8.67807 2.01199 5.63701 4.47627 3.4286C6.94055 1.22019 10.1311 -0.000520778 13.4375 1.66668e-07H72.5625Z" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Presentacion;
