import React, { useEffect, useState } from "react";

import estilo from "./App.module.css";
import Menu from "./components/Menu";
import Presentacion from "./components/Presentacion";
import SobreMi from "./components/SobreMi";
import Servicios from "./components/Servicios";
import Proyectos from "./components/Proyectos";
import Experiencia from "./components/Experiencia";
import Contactame from "./components/Contactame";
import { useContext } from "react";
import { DataContext } from "./context/Contexto";
import Formulario from "./components/Formulario";
// import Snowfall from "react-snowfall";
import { Link } from "react-scroll";
import Rata from "./components/Rata";

function App() {
  const { activarForm } = useContext(DataContext);

  const [cargando, setCargando] = useState(true);
  const [progreso, setProgreso] = useState(0);

  useEffect(() => {
    let intervalo;

    if (!intervalo) {
      intervalo = setInterval(() => {
        setProgreso((prevProgreso) => {
          if (prevProgreso < 100) {
            return prevProgreso + 1;
          } else {
            clearInterval(intervalo);
            setTimeout(() => {
              setCargando(false);
            }, 500); // Retrasar la ocultación de la pantalla de carga
            return prevProgreso;
          }
        });
      }, 20);
    }

    return () => clearInterval(intervalo);
  }, []);

  return (
    <div className={cargando ? estilo.app_corte : estilo.app}>
      {cargando ? (
        <div className={estilo.pantalla_carga}>
          <div className={estilo.contenido_carga}>
            <Rata></Rata>
            <div className={estilo.numero_carga}>
              <h2>{progreso}</h2>
              <h3>%</h3>
            </div>
          </div>
        </div>
      ) : null}

      {activarForm ? <Formulario></Formulario> : null}

      <Menu />
      <Presentacion />
      <SobreMi />
      <Proyectos />
      <Servicios />
      <Experiencia />
      <Contactame />

      <Link
        activeClass="active"
        to="presentacion"
        spy={true}
        smooth={true}
        offset={0}
        duration={750}
        className={estilo.boton_flotante}
      >
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none">
          <path d="M69.0927 36.134L37.5962 1.10855C36.2699 -0.369518 33.7222 -0.369518 32.3958 1.10855L0.899343 36.134C0.445991 36.6369 0.148413 37.2608 0.0427934 37.9299C-0.0628262 38.599 0.0280625 39.2843 0.304409 39.9027C0.864346 41.1671 2.11721 41.9797 3.49955 41.9797H10.4988V66.4975C10.4988 67.4264 10.8675 68.3173 11.5238 68.9741C12.1801 69.631 13.0702 70 13.9984 70H24.4972C25.4254 70 26.3155 69.631 26.9718 68.9741C27.6281 68.3173 27.9968 67.4264 27.9968 66.4975V52.4873H41.9952V66.4975C41.9952 67.4264 42.364 68.3173 43.0203 68.9741C43.6766 69.631 44.5667 70 45.4949 70H55.9937C56.9218 70 57.812 69.631 58.4683 68.9741C59.1246 68.3173 59.4933 67.4264 59.4933 66.4975V41.9797H66.4925C67.1703 41.9826 67.8342 41.788 68.4034 41.4198C68.9726 41.0515 69.4225 40.5255 69.6981 39.9058C69.9738 39.2861 70.0633 38.5995 69.9558 37.9298C69.8483 37.26 69.5484 36.6361 69.0927 36.134Z" />
        </svg>
      </Link>
    </div>
  );
}

export default App;
